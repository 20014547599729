.__crossword {
  padding: 20px;

  &.solved {
    pointer-events: none;

    .cell.letter {
      background: var(--solved-gradient);
      background-size: 400% 400%;
      animation: gradient 3s ease infinite;
      animation-delay: 0.5s;
    }
  }
  
  .row {
    height: 40px;
    display: flex;
    justify-content: center;
  }

  .cell {
    width: 40px;
    height: 40px;
    position: relative;
    user-select: none;

    &.letter {
      border: 1px solid var(--main-color);
      outline: 1px solid var(--main-color);
      box-shadow: 0 3px 0 1px var(--dim-background);
      background-color: var(--background);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      cursor: pointer;

      &:hover:not(.active) {
        background-color: var(--hover-cell);
      }
    }

    &.active {
      background-color: var(--dim-background);
    }
    
    &.inputting {
      
      position: relative;
      background-color: var(--active-cell);

      .caret {
        position: absolute;
        border-bottom: 2px solid var(--main-color);
        bottom: 4px;
        width: 50%;
        left: 50%;
        transform: translateX(-50%);
        animation: blink 1.5s step-end infinite;
      }
    }

    .hint-index {
      position: absolute;
      top: 1px;
      right: 2px;
      font-size: 10px;
      font-weight: bold;
    }
  }

  .confetti {
    position: fixed;

    &-1 {
      top: 33%;
      left: 50%;
    }

    &-2 {
      top: 66%;
      left: 33%;
    }

    &-3 {
      top: 66%;
      left: 66%;
    }
  }
}

.react-simple-keyboard {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;

  &.hg-theme-default {
    background-color: var(--dim-background);
    
    .hg-button {
      background: var(--background);
      border-color: var(--dim-background);
    }
  }
}

@keyframes blink {
  from, to {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
    transform: rotate(4deg);
	}
	50% {
		background-position: 100% 50%;
    transform: rotate(-4deg);
	}
	100% {
		background-position: 0% 50%;
    transform: rotate(4deg);
	}
}
