.__checkbox {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;

    input { 
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--checkbox-bg);
    transition: 0.3s;
    border-radius: 18px;

    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      transition: 0.3s;
      border-radius: 50%;
    }
  }
  
  input:checked + .slider {
    background-color: var(--checkbox-active);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--checkbox-active);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
}