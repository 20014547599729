@import 'node_modules/include-media/dist/_include-media.scss';

.__scheme-light {
  --main-color: black;
  
  --background: white;
  --dim-background: #eaeaea;
  --active-cell: #ffd06b;
  --hover-cell: #ffeb94;
  --solved-gradient: linear-gradient(-45deg, #f0960f, #fde871, #f88437, #ffffff);

  --quote-border: #fe8427;
  --quote-text: #d55004;

  --checkbox-bg: #cccccc;
  --checkbox-active: #363636;
}

.__scheme-dark {
  --main-color: #eeeeee;

  --background: #252525;
  --dim-background: #444343;
  --active-cell: #9d3802;
  --hover-cell: #ed5509;
  --solved-gradient: linear-gradient(-45deg, #c66504, #a11c01, #e35f06, #323232);

  --quote-border: #ff9544;
  --quote-text: #ff650b;

  --checkbox-bg: #6a6a6a;
  --checkbox-active: #d55004;
}

.App {
  text-align: center;
  min-height: 100vh;
  padding: 16px 0 20px;
  background-color: var(--background);
  color: var(--main-color);

  .theme-toggle {
    width: 36px;
    height: 36px;
    margin: 0 16px 0 auto;
    color: var(--background);
    background-color: var(--main-color);
    border-radius: 100%;
    padding: 6px;
    cursor: pointer;
    user-select: none;

    .__sun-icon {
      fill: var(--background);
    }
  }

  h1 {
    text-transform: uppercase;
    margin: 24px auto;

    &.narrow {
      width: 400px;
    }

    @include media("<=720px") {
      margin: 20px auto;
      max-width: 95%;
    }
  }

  a {
    color: var(--main-color);

    &:hover {
      text-decoration: solid;
    }
  }

  .crossword-explanation {
    width: 800px;
    border-top: 3px solid var(--main-color);
    display: flex;
    margin: 0 auto;
    gap: 100px;
    padding: 32px 0;
    max-width: 90%;

    @include media("<=720px") {
      flex-direction: column-reverse;
      gap: 20px;
      padding-top: 12px;
    }

    .instructions {
      text-align: left;
      line-height: 1.8rem;

      .keyboard-toggle {
        margin: 32px auto 0;
        text-align: center;
        display: block;
      }
    }
  }

  .clues {
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 32px;

    h2 {
      padding: 8px 0;
    }

    ol, ul {
      text-align: left;
      margin-inline-end: 16px;
      
      li {
        margin: 20px 0;
      }
    }

  }
  
  .keyboard-visible {
    margin-bottom: 132px;
  }

  .message {
    width: 800px;
    max-width: 95%;
    text-align: left;
    margin: 60px auto;
    
    h1 {
      text-align: center;
      border-bottom: 3px solid var(--main-color);
      padding-bottom: 20px;
    }

    p {
      line-height: 1.8rem;
    }

    pre {
      white-space: pre-wrap;
      font-size: 0.9rem;
      background-color: var(--dim-background);
      padding: 12px;
      border-radius: 8px;
      line-height: 1.4rem;
    }

    blockquote {
      color: var(--quote-text);
      font-style: italic;
      text-align: center;
      border-top: 1px solid var(--quote-border);
      border-bottom: 1px solid var(--quote-border);
      margin: 40px 24px;
    }
  }
}
